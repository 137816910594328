import IconText from '~/components/layout/IconText';
import IconEmail from '~/components/icons/streamline/IconEmail';
import IconPhone from '~/components/icons/streamline/IconPhone';
import { Column } from '~/components/layout/Sections';
import { format_phone } from '~/lib/format';
import classNames from 'classnames';

export default function ContactGrid(props: {
  customer?: string;
  contacts: { name: string; email: TEmail | null; phone: TPhone | null }[];
  full?: boolean;
  small?: boolean;
}) {
  if (!props.contacts.length) {
    return <></>;
  }

  const sorted = [...props.contacts].sort((a, b) => {
    if (a.name === props.customer) {
      return -1;
    }

    if (b.name === props.customer) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  });

  return (
    <div
      className={classNames('flex flex-wrap', {
        'text-sm gap-y-4 gap-x-10': !props.small,
        'text-xs gap-y-2 gap-x-5': props.small,
      })}
    >
      {sorted.map(({ name, email, phone }) => (
        <div key={name} className={props.full ? 'basis-full' : undefined}>
          <Column>
            {name !== props.customer && (
              <div className="font-semibold text-xs tracking-widest text-theme-faint">{name}</div>
            )}
            {email && <IconText icon={<IconEmail />} text={email} size="small" />}
            {phone && <IconText icon={<IconPhone />} text={format_phone(phone)} size="small" />}
          </Column>
        </div>
      ))}
    </div>
  );
}
